/**
 * INIT - General variables
 * jQuery version
 * by SalsaLab
 */


var salsalab = {
	breakpoints: {
		xs: 1,   // Extra small screen / phone
		sm: 576, // Small screen / phone
		md: 768, // Medium screen / tablet
		lg: 992, // Large screen / desktop
		xl: 1248 // Extra large screen / wide desktop
	}
};
