/* RENTEC Site. By SalsaLab: salsalab.mx */



(function($) {

	// mobile menu
	$('#main-menu').slb_mobile_drop_menu();


	// homepage swiper
	if($('#home-slider-v .swiper-slide').length > 1)
	{
		var homeswiper = new Swiper('#home-slider-v', {
			direction           : 'vertical',
			// freeMode            : true,
			// freeModeSticky      : true,
			keyboardControl     : true,
			mousewheelControl   : true,
			nextButton          : '#home-slider-v .swiper-button-next',
			prevButton          : '#home-slider-v .swiper-button-prev',
			pagination          : '#home-slider-v .swiper-pagination',
			paginationClickable : true,
		});

		$('.home-slider-bg').each(function(i) {

			var slide_num = $('.swiper-slide', this).length;

			new Swiper(this, {
				autoplay     : 2500,
				initialSlide : Math.round(Math.random() * slide_num),
				effect       : 'fade',
				// speed        : 300,
			});
		});
	}

	if($('.topsection-bg .swiper-slide').length > 1)
	{
		var slide_num = $('.swiper-slide', '.topsection-bg').length;

		var slide_top = new Swiper('.topsection-bg', {
			autoplay     : 2500,
			initialSlide : Math.round(Math.random() * slide_num),
			effect       : 'fade',
			// speed        : 300,
		});
	};

	// homepage swiper
	if($('#product-images').length)
	{
		var productswiper = new Swiper('#product-images', {
			onlyExternal: true
		});


		var set_thumb = function()
		{
			var target = productswiper.realIndex;
			$('#product-image-thumbs a').removeClass('active');
			$('#product-image-thumb-' + target).addClass('active');
		};


		$('#product-image-thumbs a').click(function(e)
		{
			var target = $(this).attr('data-num');
			productswiper.slideTo(target);
		});


		productswiper.on('onSlideChangeStart', set_thumb);
		set_thumb();
	}

	// scroll
	$('#go-contact').slb_smooth_scroll({
		offset: 40
	});


	// search form
	var toggleSearch = function(e)
	{
		if( ! $('body').hasClass('search'))
		{
			$('#search-form').toggleClass('opened');

			if($('#search-form').hasClass('opened'))
			{
				$('#searchfield').focus();
			}
		}
	};

	$('#show-search').click(function(e)
	{
		toggleSearch();
	});

	$('#search-form').submit(function(e)
	{
		e.preventDefault();
		var location = $(this).attr('action'),
		separator = $('#searchfield').attr('data-search-separator');
		window.location.href = location + separator + $('#searchfield').val();
	});




	// add products to cart
	$('.product-link-add').click(function(e)
	{
		e.preventDefault();

		var product = $(this).attr('data-product'),
		cart = $(this).attr('href'),
		nonce = $(this).parents('.product-recomendations, .catalog-products, .product-cta').siblings('input[name="nonce"]').val(),
		tempform = '<form action="'+cart+'" method="POST" style="display:none">' +
			'<input type="hidden" name="form-nonce" value="' + nonce + '">' +
			'<input type="hidden" name="product" value="' + product + '">' +
			'<input type="hidden" name="action" value="add">'
			'</form>';

		$(tempform).appendTo($(document.body)).submit();
	});


	// cart
	if($('body').hasClass('sec-cotizar'))
	{
		// go back to get more products
		$('.cart-goback').click(function(e)
		{
			if( ! $(this).hasClass('empty'))
			{
				e.preventDefault();
				window.history.back();
			}
		});

		// delete products
		$('.cartproduct-delete').click(function(e)
		{
			e.preventDefault();

			var path = window.location.href,
			route = $(this).parents('.cart-product').attr('data-route'),
			nonce = $(this).siblings('input[name="ajax-nonce"]').val(),
			data = {};

			data = {
				'form-nonce': nonce,
				product: route,
				action: 'delete'
			};

			// delete the product from the session
			$.post(path, data);

			// remove the product row
			$(this).parent('.cart-product').fadeOut('fast', function() {
				$(this).remove()
			});
		});

		// change quantity
		$('.product-qty input').change(function(e)
		{
			var path = window.location.href,
			route = $(this).parents('.cart-product').attr('data-route'),
			nonce = $(this).parents('.cart-product').children('input[name="ajax-nonce"]').val(),
			qty = $(this).val(),
			data = {};

			data = {
				'form-nonce': nonce,
				product: route,
				quantity: qty,
				action: 'qty'
			};

			// change product quantity in session
			$.post(path, data);
		});


		// disable products block if there are no products
		if($('.cart-goback').attr('data-length') == 0)
		{
			$('.step-3, .cart-products').hide();
			// $('#submit-cart').attr('disabled', '');
			// $('input, textarea', '#contact-form').attr('disabled', '');
		}
	}


	$('.has-errors').each(function(i)
	{
		$('input, select, textarea', this).addClass('invalid');
	});

}(jQuery));
