/**
 * NAV: Mobile drop menu
 * jQuery version
 * by SalsaLab
 */

 (function($) {

 	$.fn.slb_mobile_drop_menu = function(ops)
 	{
		// get defaults
		var _ops = $.extend({}, $.fn.slb_mobile_drop_menu.defaults, ops);
		var container = this;
		var active_class = 'active';
		var is_active = null;


		// set the behaviour
		var setup = function()
		{
			// toogle class
			$(_ops.trigger, container).click(function(e)
			{
				e.stopPropagation();
				if(is_active)
				{
					$(container).toggleClass(active_class);
				}
			});

			// prevent propagation in children
			$(_ops.menu_content, container).bind('click.slb_mobile_drop_menu', function(e)
			{
				if(is_active)
				{
					e.stopPropagation();
				}
			});

			// close clicking outside
			$('body').bind('click.slb_mobile_drop_menu', function(e)
			{
				if(is_active)
				{
					$(container).removeClass(active_class);
				}
			});

			// flag as active
			is_active = true;
		};

		// manage breakpoints
		var breakpoint = function(width)
		{
			// if the screen is mobile, run
			if(width < salsalab.breakpoints[_ops.breakpoint])
			{
				is_active = true;
			}

			else if(width >= salsalab.breakpoints[_ops.breakpoint] && is_active)
			{
				$(container).removeClass(active_class);
				is_active = false;
			}
		};

		// manage screen changes
		$(window).resize(function(e) {
			breakpoint($(window).width());
		});

		// run for the first time
		setup();
		breakpoint($(window).width());


		// return
		return this.each(function() {});
	}




	// defaults
	$.fn.slb_mobile_drop_menu.defaults = {
		trigger      : '.menu-mobile',
		menu_content : '.menu-content',
		breakpoint   : 'md'
	};

}(jQuery));
