/**
 * SCROLL: Stickybar
 * jQuery version
 * by SalsaLab
 */

(function($) {

	$.fn.slb_smooth_scroll = function(ops)
	{
		// get dedaults
		var _ops = $.extend({}, $.fn.slb_smooth_scroll.defaults, ops);
		var container = this;


		// go top
		var gotop = function(target)
		{
			var y = $(target).offset().top - _ops.offset;

			$('html, body').animate(
				{
					scrollTop: y,
				}, _ops.speed, function(e)
				{
					// focus the element
					if(_ops.focus)
					{
						if(_ops.focus === true)
						{
							$(target).focus();
						}

						else
						{
							$(_ops.focus).focus();
						}
					}

					// add the history register
					if(_ops.history && $(target).attr('id') !== undefined)
					{
						var hash = '#' + $(target).attr('id');
						history.pushState(_ops.history_obj, '', hash);
					}
				}
			);
		};


		// return
		return this.each(function()
		{
			if(_ops.event && ($(this).attr('href') || $(this).attr('data-scrolltarget')))
			{
				// event
				$(container).bind(_ops.event, function(e)
				{
					e.preventDefault();
					var target = $(this).attr('data-scrolltarget') ? $(this).attr('data-scrolltarget') : $(this).attr('href');
					gotop(target);
				});
			}
		});
	};


	// defaults
	$.fn.slb_smooth_scroll.defaults = {
		event       : 'click',
		focus       : true,
		history     : true,
		history_obj : {},
		offset      : 0,
		speed       : 250
	};

}(jQuery));
